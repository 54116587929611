
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { useStore } from "vuex";
import { Site } from "@/core/model/site";
import { useRoute } from "vue-router";
import MutualInfo from "@/views/mutual/MutualInfo.vue";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "mutual-site",
  components: {
    MutualInfo,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const site = ref<Site>({} as Site);
    const condition = ref<string>("none");
    const openModal = ref<number>(0);
    const loading = ref(false);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Administrar Mutual", ["Mutuales", "Sitio"]);
      setCurrentPageActions(false, false, []);
      loadData();
    });

    const loadData = () => {
      loading.value = true;
      store.dispatch(Actions.MUTUAL_SITE_GET).then(() => {
        condition.value = typeof store.state.MutualesModule.site.name !== 'undefined' ? "edit" : "new";
        site.value = store.state.MutualesModule.site;
        loading.value = false;
      });
    };

    const formatDateTime = (value) => {
      return value === undefined
          ? ""
          : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      formatDateTime,
      site,
      condition,
      loadData,
      openModal,
      loading,
    };
  },
});
